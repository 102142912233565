// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "f_mq";
export var warningBackgrounddark = "f_mr";
export var warningBackgroundcustom = "f_ms";
export var bottom = "f_gG";
export var modal = "f_mt";
export var container = "f_mv";
export var btnWrapper = "f_d1 d_bC d_bN d_bH";
export var elementWrapper = "f_mw d_bC d_bN d_dv";
export var titleWrapper = "f_mx d_bC d_bN";
export var cookieButtons = "f_my d_by d_bC d_bN d_bH";
export var btn = "f_mz d_by d_bC d_bN";
export var link = "f_mB";
export var decline = "f_mC f_mz d_by d_bC d_bN";
export var row = "f_mD";