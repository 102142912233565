import React from 'react';
import HTMLParser from 'html-react-parser';

import * as styles from './styles.module.css';

class HoursLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';
    const layout = props.section.styles.layout ? props.section.styles.layout : 'left';
    const full = props.section.styles.full === true;

    this.state = {
      align,
      layout,
      full,
    };
  }

  render() {
    const alignStyle = `align${this.state.align}`;
    const columns = this.state.layout === 'left' ? 'col-12 col-md-8 col-lg-6' : 'col-12 col-sm-6';
    const columnStyle = styles[`hoursInnerWrapper${this.state.layout === 'left' ? 'Left' : 'Alt'}`];
    const container =
      this.state.layout === 'left' || (this.state.layout !== 'left' && !this.state.full) ? 'container' : undefined;

    return (
      <div className={container}>
        <div className={`row ${styles[alignStyle]}`} style={!container ? { margin: 0 } : undefined}>
          <div className={`${columns} ${columnStyle}`} style={this.props.boxStyle}>
            <div className={styles.hoursInnerInnerWrapper}>
              {this.props.section.data.map((elem, i) => {
                let result;
                let content;
                let styleName;

                if (elem.active && elem.type === 'HEADINGS/HEADING-TWO') {
                  content = `<h2>${elem.text}</h2>`;
                  styleName = `${styles.hoursText} ${styles.titleMargin}`;
                } else if (elem.active && elem.type === 'PARAGRAPH/PARAGRAPH') {
                  content = `<span>${elem.text}</span>`;
                  styleName = styles.hoursText;
                }

                if (styleName) {
                  result = (
                    <div key={`${this.props.section._id}_elem_${i}`} className={styleName}>
                      {HTMLParser(content)}
                    </div>
                  );
                }

                return result;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HoursLayout;
